import { useEffect, useState, createContext, useContext } from "react"
import Loading from "../components/loading"

const AuthContext = createContext()

export function AuthContextWrap({ children }) {
  const [user, setUser] = useState()
  const [loadingAll, setLoadingAll] = useState(false)

  return (
    <AuthContext.Provider value={{ user }}>
      {loadingAll ? <Loading /> : children}
    </AuthContext.Provider>
  )
}

export function useAuthContext() {
  return useContext(AuthContext)
}
