
const NotFound = ()=>{
    return(
        <div className="relative w-full h-full">
            <div className="absolute inset-0 flex justify-center items-center w-full h-full bg-black">
                <h1 className="text-white text-xl text-white font-Quicksand font-semibold">404 || Halaman Tidak Tersedia</h1>
            </div>
        </div>
    )
}

export default NotFound