import { useEffect } from "react"
import { useHistory, Link } from "react-router-dom"
import { useAuthContext } from "../context/AuthContext"
import { motion } from "framer-motion"
import imgSampul from "../asset/MiadanTomi.png"

function MainPage(argument) {
  const { user } = useAuthContext()
  const history = useHistory()

  const tamu = window.location.href
    .split("/")[4]
    .replaceAll("#", "")
    .replaceAll("-", " ")
  return (
    <div
      className="w-full h-full relative bg-mempelai"
      style={{
        backgroundImage: `url(${imgSampul})`,
        backgroundPositionY: "center",
        backgroundPositionX: "center",
        backgroundSize: "100%",
      }}
    >
      {/* <FrameTop z_index="z-0" /> */}
      <div className="w-full h-full flex flex-col justify-center">
        {/* <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.75 }}
          transition={{ duration: 1.5, delay: 0.4 }}
          className="mx-auto block"
          src={logoTest}
          style={{ width: "60%", marginTop: "0%" }}
          alt="yu"
        /> */}
        <div className="text-center" style={{ marginTop: "50%" }}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5, delay: 0.3 }}
          >
            {/* <p
              className="text-white text-lg font-Montserrat"
              style={{ color: "grey" }}
            >
              Yth.
            </p> */}
            <h3
              // className="font-Montserrat text-lg text-kuning-tua font-semibold mt-2"
              className="font-Montserrat text-lg text-grey font-semibold mt-2"
              style={{ marginBottom: 14, marginTop: 50 }}
            >
              {tamu ? (
                tamu
              ) : (
                <div>
                  Tamu Undangan <br /> & Keluarga
                </div>
              )}
            </h3>
          </motion.div>
          <p className="flex items-center justify-center my-2 mx-auto block">
            <motion.span
              animate={{ x: 0, opacity: 1 }}
              initial={{ x: -40, opacity: 0 }}
              transition={{ duration: 1.2, delay: 0.2 }}
              className="w-8 h-1 inline-block rounded"
              style={{ backgroundColor: "#c1b4c5" }}
            ></motion.span>
            <motion.div
              className="flex items-center justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.3, delay: 0.5 }}
            >
              <span
                className="w-2 h-2 inline-block rounded-full bg-white mx-2"
                style={{ backgroundColor: "#c1b4c5" }}
              ></span>
              <span
                className="inline-block text-white text-lg tracking-wider font-Quicksand mb-1 mx-1"
                style={{ color: "#c1b4c5" }}
              >
                {/* {user ? user.address : ""}Tomi Sugiarto */} 21.09.24
              </span>
              <span
                className="w-2 h-2 inline-block rounded-full bg-white mx-2"
                style={{ backgroundColor: "#c1b4c5" }}
              ></span>
            </motion.div>
            <motion.span
              animate={{ x: 0, opacity: 1 }}
              initial={{ x: 40, opacity: 0 }}
              transition={{ duration: 1.2, delay: 0.2 }}
              className="w-8 h-1 inline-block rounded"
              style={{ backgroundColor: "#c1b4c5" }}
            ></motion.span>
          </p>
          <Link to="/invitation">
            <motion.button
              initial={{ opacity: 0, y: 35 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5, delay: 0.3 }}
              // style={{boxShadow: '0 0 10px 3px #fff'}}
              className="px-4 py-1 font-Quicksand text-md mt-4 rounded-xl -z-10"
              style={{
                color: "white",
                fontWeight: "500",
                fontSize: 14,
                padding: "10px 25px 10px 25px",
                backgroundColor: "#c1b4c5",
              }}
            >
              <div
              // onClick={playAudio}
              >
                Buka Undangan
              </div>
            </motion.button>
          </Link>
        </div>
      </div>
      {/* <FrameBottom z_index="z-0" /> */}
    </div>
  )
}

export default MainPage
