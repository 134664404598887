import { HashRouter as Router, Switch, Route } from "react-router-dom"

import MainPage from "./pages/main.js"
import Invitation from "./pages/invitation"
import SignUp from "./pages/signup.js"
import Login from "./pages/login.js"
import { AuthContextWrap } from "./context/AuthContext.js"
import NotFound from "./components/404.js"
import Generate from "./pages/generate.jsx"
import URL_MUSIC from "../src/asset/wedding-music.wav"
import { useEffect, useState } from "react"

function App() {
  const [play, setPlay] = useState(0)
  const audio = new Audio(URL_MUSIC)
  useEffect(() => {
    if (play === 1) {
      audio.play()
    }
  }, [play])

  console.log(play)

  return (
    <AuthContextWrap>
      <Router>
        <main
          className="max-w-lg mx-auto"
          onClick={() => setPlay(play + 1)}
          style={{ height: "100vh" }}
        >
          {/* <Route element={<Generate />} path="/generate" /> */}

          <Switch>
            <Route path="/invitation">
              <Invitation />
            </Route>
            <Route exact path="/">
              <MainPage />
            </Route>
            <Route component={<NotFound />} />
          </Switch>
        </main>
      </Router>
    </AuthContextWrap>
  )
}

export default App
